<template>
  <div>
    <div class="table-bar-wrapper">
      <a-form :model="data.formState" ref="formRef">
        <a-row :gutter="16">
          <!-- <a-col class="gutter-row" :span="5">
            <a-form-item label="注册企业名称" name="enterpriseName">
              <a-input v-model:value="data.formState.enterpriseName" />
            </a-form-item>
          </a-col> -->
          <a-col class="gutter-row" :span="5">
            <a-form-item label="产品名称" name="productName">
              <a-input
                v-model:value.trim="data.formState.productName"
                placeholder="请输入产品名称"
              />
            </a-form-item>
          </a-col>
          <a-col class="gutter-row" :span="5">
            <a-form-item label="批次号" name="batchNo">
              <a-input
                v-model:value.trim="data.formState.batchNo"
                placeholder="请输入生产批次号"
              />
            </a-form-item>
          </a-col>
          <a-col class="gutter-row" :span="5">
            <a-form-item label="生产日期" name="produceDate">
              <a-date-picker
                :locale="locale"
                v-model:value="data.formState.produceDate"
                value-format="YYYY-MM-DD"
                style="width: 100%"
                placeholder="请选择生产日期"
              />
              <!-- <a-range-picker
                :locale="locale"
                :placeholder="['开始', '结束']"
                @change="onChange"
              /> -->
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-space>
              <a-button type="primary" @click="clickGetUdiRecordInfo">
                <template #icon><SearchOutlined /></template>
                查询
              </a-button>
              <a-button @click="resetForm">
                <template #icon><SyncOutlined /></template>
                重置
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-button type="primary" @click="addInfo">
        <template #icon><PlusOutlined /></template>
        新建
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data.tableData"
      :rowKey="(record) => record.id"
      :pagination="false"
    >
      <template v-slot:bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex == 'action'">
          <a-space>
            <a @click="download(text, record, index)">下载 </a>
          </a-space>
        </template>
        <template v-if="column.dataIndex == 'state'">
          <a-tag color="default" v-if="record.state === 0"> 未生码 </a-tag>
          <a-tag color="processing" v-if="record.state === 1"> 生码中 </a-tag>
          <a-tag color="success" v-if="record.state === 2"> 生码完成 </a-tag>
          <a-tag color="error" v-if="record.state === 3"> 生码失败 </a-tag>

          <!-- <a-space v-if="record.state === 1"> 生码中 </a-space>
          <a-space v-if="record.state === 2"> 生码完成 </a-space>
          <a-space v-if="record.state === 3"> 生码失败 </a-space> -->
        </template>
      </template>
    </a-table>
    <!-- <Pagination :pagination="data.pagination" /> -->
    <a-pagination
      style="text-align: right; padding-top: 20px"
      v-model:current="data.pagination.pageNum"
      v-model:pageSize="data.pagination.pageSize"
      :page-size-options="data.pagination.pageSizeOptions"
      show-size-changer
      @showSizeChange="onShowSizeChange"
      @change="onPageNumChange"
      :total="data.pagination.total"
    >
      <template #buildOptionText="props">
        <span>{{ props.value }}条/页</span>
      </template></a-pagination
    >
    <a-modal
      v-model:visible="visible"
      centered
      width="60%"
      title="新建UDI"
      cancelText="取消"
      okText="确定"
      @ok="handleOk"
      @cancel="cancel"
    >
      <a-form
        ref="addFormRef"
        :rules="rules"
        :model="data.formData"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="产品名称：" required name="productName">
          <a-select
            v-model:value="data.formData.productName"
            style="width: 100%"
            show-search
            :options="data.productNames"
            :field-names="{ label: 'productName', value: 'productName' }"
            placeholder="请选择产品名称"
            @change="selectProductName"
          ></a-select>
        </a-form-item>
        <a-form-item
          label="产品规格："
          :rules="[{ required: true, message: '请选择产品规格' }]"
          name="productSpec"
        >
          <a-select
            v-model:value="data.formData.productSpec"
            style="width: 100%"
            show-search
            :options="data.productSpecs"
            :field-names="{ label: 'productSpec', value: 'productSpec' }"
            placeholder="请选择产品规格"
            @change="selectProductSpec"
          ></a-select>
        </a-form-item>
        <a-form-item
          label="UDI规则："
          :rules="[{ required: true, message: '请选择UDI规则' }]"
          name="UDIConfig"
        >
          <!-- <a-input
            v-model:value="data.formData.UDIConfig"
            style="width: 100%"
            show-search
            :options="data.UDIConfigs"
            :field-names="{ label: 'enterpriseName', value: 'enterpriseName' }"
            placeholder="请选择UDI规则"
            @change="handleChange"
          /> -->
          <a-select
            v-model:value="data.formData.UDIConfig"
            style="width: 100%"
            show-search
            :options="data.UDIConfigs"
            :field-names="{ label: 'configName', value: 'id' }"
            placeholder="请选UDI规则"
            @change="selectUDIConfig"
          ></a-select>
        </a-form-item>
        <!-- <a-form-item label="批准日期：" required name="aproveDate"> -->
        <!-- <a-input
            v-model:value="data.formData.aproveDate"
            placeholder="请选择批准日期"
          /> -->
        <a-form-item
          name="produceDate"
          :rules="[{ required: true, message: '请选择生产日期' }]"
          label="生产日期："
        >
          <a-date-picker
            :locale="locale"
            v-model:value="data.formData.produceDate"
            value-format="YYYY-MM-DD"
            style="width: 100%"
            placeholder="请选择生产日期"
          />
        </a-form-item>

        <span>
          <block v-for="(value, index) in data.aiNames" :key="index">
            <a-form-item
              name="expiringDateRange"
              label="保质期："
              v-if="
                data.aiCodes[index] == '17' && data.hasExpiringDateChange == 1
              "
            >
              {{ hasExpiringDateChange }}
              <a-input-group compact>
                <a-input
                  v-model:value="data.expiringDateRange"
                  @change="expiringDateChange"
                  style="width: 85%"
                />
                <a-select
                  v-model:value="data.dateFlag"
                  @change="expiringDateChange"
                  style="width: 15%"
                >
                  <a-select-option value="0">年</a-select-option>
                  <a-select-option value="1">月</a-select-option>
                  <a-select-option value="2">日</a-select-option>
                </a-select>
              </a-input-group>
            </a-form-item>
            <a-form-item
              v-if="data.aiCodes[index] != '01' && data.aiCodes[index] != '11'"
              :rules="[{ required: true, message: '请输入' + value }]"
              :label="value"
            >
              <span v-if="data.aiCodes[index] == '17'">
                <a-date-picker
                  :locale="locale"
                  v-model:value="data.formData.customValue[index]"
                  value-format="YYYY-MM-DD"
                  style="width: 100%"
                  :placeholder="'请选择' + value"
                />
              </span>
              <span v-else>
                <a-input
                  v-model:value="data.formData.customValue[index]"
                  :placeholder="'请输入' + value"
                />
              </span>
            </a-form-item>
          </block>
          <!-- 
              
             -->
        </span>

        <!-- <a-form-item name="effectiveDate" :required="true" label="生效日期：">
          <a-date-picker
            :locale="locale"
            v-model:value="data.formData.effectiveDate"
            value-format="YYYY-MM-DD"
            style="width: 100%"
            placeholder="请选择生效日期"
          />
        </a-form-item>
        <a-form-item name="expiringDate" label="失效日期：">
          <a-date-picker
            :locale="locale"
            v-model:value="data.formData.expiringDate"
            value-format="YYYY-MM-DD"
            style="width: 100%"
            placeholder="请选择失效日期"
          />
        </a-form-item>
        <a-form-item label="注册产品标识：" name="productCode">
          <a-input
            v-model:value="data.formData.productCode"
            placeholder="请输入注册产品标识"
          />
        </a-form-item> -->
        <a-form-item label="生码数量：" required name="codeCount">
          <a-input
            v-model:value="data.formData.codeCount"
            placeholder="请输入生码数量"
          />
        </a-form-item>
        <a-form-item label="UDI预览：" name="remark">
          <a-input
            disabled
            v-model:value="data.UDIReview"
            placeholder="UDI预览"
          />
        </a-form-item>
        <a-form-item label="备注：" name="remark">
          <a-input
            v-model:value="data.formData.remark"
            placeholder="请输入备注"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <iframe name="myIframe" style="display: none"></iframe>
  </div>
</template>
<script>
import {
  SearchOutlined,
  SyncOutlined,
  PlusOutlined,
  SmileOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import Pagination from "@/components/Pagination";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
// import ajax from "../../js/ajax.js";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  toRaw,
} from "vue";
import { number } from "vue-types";
import { json } from "body-parser";
const columns = [
  {
    title: "序号",
    customRender: ({ text, record, index }) => `${index + 1}`,
  },
  {
    title: "产品名称",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "产品规格",
    key: "productSpec",
    dataIndex: "productSpec",
  },
  {
    title: "GTIN",
    key: "gtin",
    dataIndex: "gtin",
  },
  {
    title: "生产批次",
    key: "batchNo",
    dataIndex: "batchNo",
    customRender: (text) => {
      return text.text ? text.text : "无批号";
    },
  },
  {
    title: "生码数量",
    key: "codeCount",
    dataIndex: "codeCount",
  },
  {
    title: "生产日期",
    key: "produceDate",
    dataIndex: "produceDate",
    customRender: (text) => {
      return moment(text.text).format("YYYY-MM-DD");
    },
  },
  {
    title: "失效日期",
    key: "expDate",
    dataIndex: "expDate",
    customRender: (text) => {
      return moment(text.text).format("YYYY-MM-DD");
    },
  },
  {
    title: "生码日期",
    key: "createTime",
    dataIndex: "createTime",
    customRender: (text) => {
      return moment(text.text).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "状态",
    key: "state",
    dataIndex: "state",
  },
  {
    title: "备注",
    key: "remark",
    dataIndex: "remark",
  },
  // {
  //   title: "操作者",
  //   key: "operator",
  //   dataIndex: "operator",
  // },
  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    // slots: { customRender: "action" },
  },
];
const rules = {
  productName: [
    {
      required: true,
      message: "请输入产品名称",
      trigger: "blur",
    },
  ],
  legalPerson: [
    {
      required: true,
      message: "请输入注册人/备案人",
      trigger: "blur",
    },
  ],
  creditCode: [
    {
      required: true,
      message: "请输入注册备案号",
      trigger: "blur",
    },
  ],
  codeCount: [
    {
      required: true,
      message: "请输入生码数量",
      trigger: "blur",
    },
  ],
};
// const pagination = {
//   pageSize: ref(10),
//   pageNum: 1,
//   current: ref(2),
//   total: ref(0),
//   pageSizeOptions: ["10", "20"],
// };
export default defineComponent({
  components: {
    SearchOutlined,
    SyncOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    Pagination,
  },
  setup() {
    const { http } = getCurrentInstance().appContext.config.globalProperties;
    const formRef = ref();
    const addFormRef = ref();
    const visible = ref(false);
    const data = reactive({
      formState: {},
      pagination: {
        pageSize: 10,
        pageNum: 1,
        current: 1,
        total: 1,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
      },
      // productSpecRule: [{ required: true, message: "请选择产品规格" }],
      formData: {
        customValue: ["2023-05-04"],
      },
      aiCodes: [],
      aiNames: [],
      UDIReview: "",
      productNames: [],
      productSpecs: [],
      UDIConfigs: [],
      modalTitle: "新增",
      tableData: [],
      expiringDateRange: "",
      dateFlag: "0",
      hasExpiringDateChange: 0,
    });
    const onChange = (value, dateString) => {
      console.log("Selected Time: ", moment(value));
      console.log("Formatted Selected Time: ", dateString);
      data.formState.rangeDate = dateString;
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const test = () => {
      http
        .post("/udiRecord/test")
        .then((res) => {
          if (res.data.state) {
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const addInfo = (text, record, index) => {
      record && (data.formData = record);
      if (record) {
        data.formData.aproveDate = moment(toRaw(text).aproveDate).format(
          "YYYY-MM-DD"
        );
        data.formData.effectiveDate = moment(toRaw(text).effectiveDate).format(
          "YYYY-MM-DD"
        );
        data.formData.expiringDate = moment(toRaw(text).expiringDate).format(
          "YYYY-MM-DD"
        );
      }
      record ? (data.modalTitle = "编辑") : (data.modalTitle = "新增");
      visible.value = true;
    };
    function delCertificate(id) {
      http
        .post("/registercertificate/delete", { id: id })
        .then((res) => {
          if (res.data.state) {
            message.success(res.data.message);
            getCertificateInfo();
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    const confirm = (e) => {
      delCertificate(e.id);
    };
    // function getCertificateInfo() {
    //   http
    //     .post(
    //       "/registercertificate/getList",
    //       Object.assign(toRaw(data.formState), {
    //         pageSize: data.pagination.pageSize,
    //         pageNum: data.pagination.pageNum,
    //       })
    //     )
    //     .then((res) => {
    //       console.log("获取注册证列表", res);
    //       console.log("res.data.total", res.data.total);
    //       if (res.data.state) {
    //         data.tableData = res.data.data;
    //         data.pagination.total = res.data.total;
    //         message.success(res.data.message);
    //       } else {
    //         message.error(res.data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // }
    // function resetFormData() {
    //   return {
    //     productName: "",
    //     legalPerson: "",
    //     creditCode: "",
    //     aproveDate: "",
    //     effectiveDate: "",
    //     expiringDate: "",
    //     productCode: "",
    //     remark: "",
    //   };
    // }
    const cancel = () => {
      data.formData = {};
      data.formData.customValue = [];
    };
    const onShowSizeChange = (current, pageSize) => {
      console.log(current, pageSize);
      data.pagination.pageNum = 1;
      data.pagination.pageSize = pageSize;
      getUdiRecordInfo();
    };
    const onPageNumChange = (current, pageSize) => {
      data.pagination.pageNum = current;
      getUdiRecordInfo();
    };
    const clickGetUdiRecordInfo = () => {
      data.pagination.pageNum = 1;
      getUdiRecordInfo();
    };
    const getProducts = () => {
      http
        .post("/udiRecord/getProduct")
        .then((res) => {
          if (res.data.state) {
            message.success(res.data.message);
            data.productNames = res.data.data;
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const selectProductName = (v) => {
      v && getProductSpecs(v);
    };
    const selectProductSpec = (v) => {
      v && getUdiconfigInfo();
    };
    const selectUDIConfig = (v) => {
      data.aiCodes = [];
      data.aiNames = [];
      data.formData.customValue = [];
      let configs = data.UDIConfigs.filter((item) => {
        return v == item.id;
      });
      console.log(toRaw(configs[0]));
      let aiCodes = toRaw(configs[0]).aiCodes.split(",");
      console.log(aiCodes);
      aiCodes.forEach((item, index) => {
        if (item != "01" && item != "11" && item != "21") {
          data.aiCodes.push(item);
          data.aiNames.push(toRaw(configs[0]).aiNames.split(",")[index]);
        }
      });
    };
    const getProductSpecs = (productName) => {
      data.formData.productSpec = undefined;
      http
        .post("/udiRecord/getProductSpec", { productName: productName })
        .then((res) => {
          if (res.data.state) {
            message.success(res.data.message);
            data.productSpecs = res.data.data;
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const download = (text, record, index) => {
      // window.open(
      //   "http://localhost:3080/public/UDICodes/2022-10-11/1665488724088.txt",
      //   "myIframe"
      // );
      // window.open("http://localhost:3080/udiRecord/download", "_self");
      // ajax.downloadFile(
      //   "http://localhost:3080/public/UDICodes/2022-10-11/1665462254630.txt",
      //   null
      // );
      console.log(toRaw(record));
      let protocol = window.location.protocol;
      let host = window.location.host;
      http
        .post("/udiRecord/download", toRaw(record))
        .then((res) => {
          let url = protocol + "//" + host + res.data.path;
          window.open(url, "myIframe");
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const getUdiconfigInfo = () => {
      http
        .post("/udiConfig/getList", { pageNum: 1, pageSize: 1000 })
        .then((res) => {
          if (res.data.state) {
            message.success(res.data.message);
            data.UDIConfigs = res.data.data;
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const expiringDateChange = () => {
      console.log(data.expiringDateRange);
      console.log(data.dateFlag);
      console.log(data.formData.produceDate);
      let addNumber = parseInt(data.expiringDateRange) || 0;
      let date = new Date(data.formData.produceDate);
      console.log("date.getFullYear()", date.getFullYear());
      let newDate = new Date(addDate(date, addNumber) - 24 * 60 * 60 * 1000);
      newDate = `${newDate.getFullYear()}-${
        newDate.getMonth() + 1 > 9
          ? newDate.getMonth() + 1
          : "0" + (newDate.getMonth() + 1)
      }-${newDate.getDate() > 9 ? newDate.getDate() : "0" + newDate.getDate()}`;
      data.formData.customValue[0] = newDate;
    };
    const addDate = (date, addNumber = 0) => {
      switch (data.dateFlag) {
        case "0":
          return date.setFullYear(date.getFullYear() + addNumber);
          break;
        case "1":
          return date.setMonth(date.getMonth() + addNumber);
          break;
        case "2":
          return date.setDate(date.getDate() + addNumber);
          break;
        default:
          return date.setDate(date.getDate() + addNumber);
          break;
      }
    };
    const getUdiRecordInfo = () => {
      http
        .post(
          "/udiRecord/getList",
          Object.assign(toRaw(data.formState), {
            pageSize: data.pagination.pageSize,
            pageNum: data.pagination.pageNum,
          })
        )
        .then((res) => {
          console.log("获取注册证列表", res);
          console.log("res.data.total", res.data.total);
          if (res.data.state) {
            data.tableData = res.data.data;
            data.pagination.total = res.data.total;
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const handleOk = () => {
      console.log(data.formData.customValue);
      addFormRef.value
        .validate()
        .then(() => {
          http
            .post(
              "/udiRecord/addUdiRecord",
              Object.assign({}, toRaw(data.formData), {
                aiNames: data.aiNames,
                aiCodes: data.aiCodes,
              })
            )
            .then((res) => {
              if (res.data.state) {
                message.success(res.data.message);
                visible.value = false;
                cancel();
                getUdiRecordInfo();
              } else {
                message.error(res.data.message);
              }
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    onMounted(async () => {
      setTimeout(() => {
        getProducts();
      }, 100);
      getUdiRecordInfo();
      data.hasExpiringDateChange = JSON.parse(
        localStorage.getItem("userInfo")
      ).hasExpiringDateChange;
      console.log("data.hasExpiringDateChange", data.hasExpiringDateChange);
    });
    return {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns,
      addFormRef,
      formRef,
      data,
      // pagination,
      visible,
      rules,
      locale,
      onShowSizeChange,
      onPageNumChange,
      resetForm,
      confirm,
      cancel,
      onChange,
      addInfo,
      handleOk,
      cancel,
      selectProductName,
      download,
      selectProductSpec,
      selectUDIConfig,
      getUdiRecordInfo,
      clickGetUdiRecordInfo,
      expiringDateChange,
    };
  },
});
</script>
<style scoped>
/* /deep/ .ant-table-thead > tr > th {
  text-align: center;
} */
</style>
