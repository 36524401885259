<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <div class="table-bar-wrapper">
      <a-form :model="data.formState" ref="formRef">
        <a-row :gutter="16">
          <!-- <a-col class="gutter-row" :span="5">
            <a-form-item label="注册企业名称" name="factoryName">
              <a-input v-model:value="data.formState.factoryName" />
            </a-form-item>
          </a-col> -->
          <a-col class="gutter-row" :span="5">
            <a-form-item label="注册产品名称" name="productName">
              <a-input v-model:value="data.formState.productName" allowClear />
            </a-form-item>
          </a-col>
          <a-col class="gutter-row" :span="5">
            <a-form-item label="产品规格" name="productSpec">
              <a-input v-model:value="data.formState.productSpec" allowClear />
            </a-form-item>
          </a-col>
          <!-- <a-col class="gutter-row" :span="5">
            <a-form-item label="注册备案号" name="creditCode">
              <a-input v-model:value="data.formState.creditCode" allowClear />
            </a-form-item>
          </a-col> -->
          <!-- <a-col class="gutter-row" :span="5">
            <a-form-item label="创建时间" name="date">
              <a-range-picker
                :locale="locale"
                v-model:value="data.formState.date"
                :placeholder="['开始', '结束']"
                @change="onChange"
                allowClear
              />
            </a-form-item>
          </a-col> -->
          <a-col :span="4">
            <a-space>
              <a-button type="primary">
                <template #icon><SearchOutlined /></template>
                查询
              </a-button>
              <a-button @click="resetForm">
                <template #icon><SyncOutlined /></template>
                重置
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div style="margin-bottom: 10px">
      <a-space>
        <a-button type="primary" @click="addInfo">
          <template #icon><PlusOutlined /></template>
          新建
        </a-button>
        <!-- <a-upload
          v-model:file-list="fileList"
          name="file"
          :multiple="false"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          :headers="headers"
          @change="handleChange"
        >
          <a-button>
            <UploadOutlined />
            导入数据
          </a-button>
        </a-upload>
        <a-button @click="addInfo">
          <template #icon><RetweetOutlined /></template>
          同步数据
        </a-button>
        <a-button @click="addInfo" :disabled="!hasSelected">
          <template #icon><ArrowUpOutlined /></template>
          批量上传
        </a-button> -->
        <a-button @click="addInfo" :disabled="!hasSelected">
          <template #icon><DeleteOutlined /></template>
          批量删除
        </a-button>
      </a-space>
    </div>
    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="data.tableData"
      :pagination="false"
    >
      <!-- <template #expandedRowRender>
        <a-table
          :columns="innerColumns"
          :data-source="innerData"
          :pagination="false"
        >
        </a-table>
      </template> -->
      <template v-slot:bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex == 'action'">
          <a-space>
            <a @click="submit(text, record, index)">上传 </a>
            <a @click="addInfo(text, record, index)">编辑 </a>
            <a-popconfirm
              title="确定删除这条信息吗?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirm(record)"
              @cancel="cancel(record)"
            >
              <a> 删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
      <!-- <template #action="{ text, record, index }">
        <a-space>
          <a  @click="submit(text, record, index)">上传 </a>
          <a  @click="addInfo(text, record, index)">编辑 </a>
          <a-popconfirm
            title="确定删除这条信息吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(record)"
            @cancel="cancel(record)"
          >
            <a > 删除</a>
          </a-popconfirm>
        </a-space>
      </template> -->
    </a-table>
    <a-pagination
      style="text-align: right; padding-top: 20px"
      v-model:current="data.pagination.pageNum"
      v-model:pageSize="data.pagination.pageSize"
      :page-size-options="data.pagination.pageSizeOptions"
      show-size-changer
      @showSizeChange="onShowSizeChange"
      @change="onPageNumChange"
      :total="data.pagination.total"
    >
      <template #buildOptionText="props">
        <span>{{ props.value }}条/页</span>
      </template></a-pagination
    >
    <a-modal
      v-model:visible="submitVisible"
      :centered="true"
      width="96%"
      title="上传国家数据库信息"
      cancelText="保存到草稿箱"
      okText="上传至国家数据库"
      @ok="handleOk"
      @cancel="cancel"
    >
      <a-form
        ref="addFormRef"
        :rules="submitRules"
        :model="data.submitFormData"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <div class="form-container">
          <a-row>
            <a-col :span="24">产品标识基本信息</a-col>
          </a-row>
          <a-divider />
          <a-row :gutter="[0, 2]">
            <a-col :span="12"
              ><a-form-item
                label="最小销售单元产品标识："
                required
                name="ZXXSDYCPBS"
              >
                <!-- 默认当前登录的企业名称 -->
                <a-input
                  v-model:value="data.submitFormData.ZXXSDYCPBS"
                  placeholder="请输入最小销售单元产品标识"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item label="产品标识编码体系名称：" name="CPBSBMTXMC">
                <a-select
                  v-model:value="data.submitFormData.CPBSBMTXMC"
                  disabled
                  placeholder="请选择编码体系名称"
                >
                  <a-select-option value="GS1">GS1</a-select-option>
                  <a-select-option value="MA码(IDCODE)"
                    >MA(中关村)</a-select-option
                  >
                  <a-select-option value="AHM">阿里健康</a-select-option>
                </a-select>
              </a-form-item></a-col
            >
            <a-col :span="12"
              ><a-form-item label="产品标识发布日期：" required name="CPBSFBRQ">
                <a-date-picker
                  style="width: 100%"
                  :locale="locale"
                  :disabled-date="disabledDate"
                  :showToday="false"
                  placeholder="请选择日期"
                  v-model:value="data.submitFormData.CPBSFBRQ"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item
                label="最小销售单元中使用单元的数量："
                required
                name="ZXXSDYZSYDYDSL"
              >
                <a-input
                  v-model:value="data.submitFormData.ZXXSDYZSYDYDSL"
                  placeholder="请输入最小销售单元中使用单元的数量"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item
                label="使用单元产品标识："
                :required="data.submitFormData.ZXXSDYZSYDYDSL > 1"
                name="SYDYCPBS"
              >
                <a-input
                  v-model:value="data.submitFormData.SYDYCPBS"
                  :disabled="!(data.submitFormData.ZXXSDYZSYDYDSL > 1)"
                  placeholder="请输入使用单元产品标识"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item label="标识载体(多选)：" required name="BSZT">
                <a-checkbox-group
                  v-model:value="data.submitFormData.BSZT"
                  @change="getBSZT"
                >
                  <a-checkbox value="1">一维码</a-checkbox>
                  <a-checkbox value="2">二维码</a-checkbox>
                  <a-checkbox value="3">RFID</a-checkbox>
                  <a-checkbox value="4">其他</a-checkbox>
                </a-checkbox-group>
              </a-form-item></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="是否与注册/备案产品标识一致："
                required
                name="SFYZCBAYZ"
              >
                <a-radio-group v-model:value="data.submitFormData.SFYZCBAYZ">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group>
              </a-form-item></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="注册/备案产品标识："
                :required="data.submitFormData.SFYZCBAYZ == 0"
                name="ZCBACPBS"
              >
                <a-input
                  v-model:value="data.submitFormData.ZCBACPBS"
                  :disabled="data.submitFormData.SFYZCBAYZ == 1"
                  placeholder="请输入注册/备案产品标识"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item
                label="是否有本体直接标识："
                required
                name="SFYBTZJBS"
              >
                <a-radio-group v-model:value="data.submitFormData.SFYBTZJBS">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group>
              </a-form-item></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="与最小销售单元产品标识一致："
                required
                v-if="data.submitFormData.SFYBTZJBS == 1"
                name="BTCPBSYZXXSDYCPBSSFYZ"
              >
                <a-radio-group
                  v-model:value="data.submitFormData.BTCPBSYZXXSDYCPBSSFYZ"
                >
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group>
              </a-form-item></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="本体产品标识："
                v-if="data.submitFormData.BTCPBSYZXXSDYCPBSSFYZ == 0"
                :required="data.submitFormData.BTCPBSYZXXSDYCPBSSFYZ == 0"
                name="BTCPBS"
              >
                <a-input
                  v-model:value="data.submitFormData.BTCPBS"
                  placeholder="请输入本体产品标识"
                /> </a-form-item
            ></a-col>
          </a-row>
        </div>
        <div class="form-container">
          <a-row>
            <a-col :span="24">产品基本信息</a-col>
          </a-row>
          <a-divider />
          <a-row :gutter="[0, 4]">
            <a-col :span="12"
              ><a-form-item
                label="产品名称/通用名称："
                required
                name="CPMCTYMC"
              >
                <!-- 默认当前登录的企业名称 -->
                <a-input
                  v-model:value="data.submitFormData.CPMCTYMC"
                  placeholder="请输入产品名称/通用名称"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item label="商品名称：" name="SPMC">
                <!-- 默认当前登录的企业名称 -->
                <a-input
                  v-model:value="data.submitFormData.SPMC"
                  placeholder="请输入商品名称"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item label="规格型号：" required name="GGXH">
                <!-- 默认当前登录的企业名称 -->
                <a-input
                  v-model:value="data.submitFormData.GGXH"
                  placeholder="请输入商品规格型号"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item
                label="是否为包类/组套类产品："
                required
                name="SFWBLZTLCP"
              >
                <!-- 默认当前登录的企业名称 -->
                <a-radio-group v-model:value="data.submitFormData.SFWBLZTLCP">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
            <a-col :span="12"
              ><a-form-item label="产品描述：" required name="CPMS">
                <!-- 默认当前登录的企业名称 -->
                <a-textarea
                  v-model:value="data.submitFormData.CPMS"
                  placeholder="请输入产品描述"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item label="医保编码：" name="YBBM">
                <!-- 默认当前登录的企业名称 -->
                <a-textarea
                  v-model:value="data.submitFormData.YBBM"
                  placeholder="请输入医保编码（如果有多个编码，各个编码之间用英文符号‘,’ 分隔）"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item label="器械类别：" required name="CPLX">
                <!-- 默认当前登录的企业名称 -->
                <a-radio-group v-model:value="data.submitFormData.CPLX">
                  <a-radio value="1">器械</a-radio>
                  <a-radio value="2">体外诊断试剂</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
            <a-col :span="12">
              <a-form-item label="分类编码：" required name="FLBM">
                <a-cascader
                  v-model:value="data.submitFormData.FLBM"
                  :options="flbmOptions"
                  placeholder="请选择分类编码"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="注册证/备案凭证编号：" required name="BAH">
                <a-select
                  v-model:value="data.submitFormData.BAH"
                  :options="data.BAHoptions"
                  placeholder="请选择注册证/备案凭证号"
                  @change="getBAH"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12"
              ><a-form-item label="产品类别：" required name="HCHZSB">
                <!-- 默认当前登录的企业名称 -->
                <a-radio-group v-model:value="data.submitFormData.HCHZSB">
                  <a-radio value="0">耗材</a-radio>
                  <a-radio value="1">设备</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="磁共振（MR）安全相关信息："
                required
                name="CGZMRAQXGXX"
              >
                <a-select
                  ref="select"
                  v-model:value="data.submitFormData.CGZMRAQXGXX"
                >
                  <a-select-option value="0">安全</a-select-option>
                  <a-select-option value="1">条件安全</a-select-option>
                  <a-select-option value="2">不安全</a-select-option>
                  <a-select-option value="3"
                    >说明书或标签不包含MR安全性信息</a-select-option
                  >
                </a-select></a-form-item
              ></a-col
            >
            <a-col :span="12"
              ><a-form-item label="其他信息的网址链接：" name="QTXXDWZLJ">
                <a-input
                  v-model:value="data.submitFormData.QTXXDWZLJ"
                  placeholder="请输入其他信息的网址链接"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item
                label="是否标记为一次性使用："
                required
                name="SFBJWYCXSY"
              >
                <a-radio-group v-model:value="data.submitFormData.SFBJWYCXSY">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="最大重复使用次数："
                :required="data.submitFormData.SFBJWYCXSY == 0"
                name="ZDCFSYCS"
              >
                <a-input
                  v-model:value="data.submitFormData.ZDCFSYCS"
                  :disabled="data.submitFormData.SFBJWYCXSY == 1"
                  placeholder="请输入最大重复使用次数"
                /> </a-form-item
            ></a-col>
            <a-col :span="12"
              ><a-form-item label="是否为无菌包装：" required name="SFWWJBZ">
                <a-radio-group v-model:value="data.submitFormData.SFWWJBZ">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="使用前是否需要灭菌："
                required
                name="SYQSFXYJXMJ"
              >
                <a-radio-group v-model:value="data.submitFormData.SYQSFXYJXMJ">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="灭菌方式："
                :required="data.submitFormData.SYQSFXYJXMJ == 1"
                name="MJFS"
              >
                <a-textarea
                  v-model:value="data.submitFormData.MJFS"
                  :disabled="data.submitFormData.SYQSFXYJXMJ == 0"
                  placeholder="请输入灭菌方式"
                /> </a-form-item
            ></a-col>
          </a-row>
        </div>
        <div class="form-container">
          <a-row>
            <a-col :span="24">生产标识信息</a-col>
          </a-row>
          <a-divider />
          <a-row :gutter="[0, 4]">
            <a-col :span="12"
              ><a-form-item
                label="生产标识是否包含批号："
                required
                name="SCBSSFBHPH"
              >
                <a-radio-group v-model:value="data.submitFormData.SCBSSFBHPH">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >

            <a-col :span="12"
              ><a-form-item
                label="生产标识是否包含生产日期："
                required
                name="SCBSSFBHSCRQ"
              >
                <a-radio-group v-model:value="data.submitFormData.SCBSSFBHSCRQ">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="生产标识是否包序列号："
                required
                name="SCBSSFBHXLH"
              >
                <a-radio-group v-model:value="data.submitFormData.SCBSSFBHXLH">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
            <a-col :span="12"
              ><a-form-item
                label="生产标识是否包含失效日期："
                required
                name="SCBSSFBHSXRQ"
              >
                <a-radio-group v-model:value="data.submitFormData.SCBSSFBHSXRQ">
                  <a-radio value="1">是</a-radio>
                  <a-radio value="0">否</a-radio>
                </a-radio-group></a-form-item
              ></a-col
            >
          </a-row>
        </div>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visible"
      :centered="true"
      width="900px"
      :title="data.modalTitle + '产品'"
      cancelText="取消"
      okText="确定"
      @ok="handleOk"
      @cancel="cancel"
    >
      <a-form
        ref="addFormRef"
        :rules="rules"
        :model="data.formData"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="产品名称：" required name="productName">
          <!-- 默认当前登录的企业名称 -->
          <a-input
            v-model:value="data.formData.productName"
            placeholder="请输入产品名称"
          />
        </a-form-item>
        <!-- <a-form-item label="UDI配置：" required name="udiConfig">
          <a-select
            v-model:value="data.formData.udiConfig"
            :options="BAHoptions"
            placeholder="请选择UDI配置信息"
            @change="getBAH"
          >
          </a-select>
        </a-form-item> -->
        <!-- <a-form-item label="注册备案号：" required name="BAH"> -->
        <!-- <a-select
            v-model:value="data.formData.BAH"
            :options="BAHoptions"
            placeholder="请选择注册证/备案凭证号"
            @change="getBAH"
          >
          </a-select> -->
        <!--      :field-names="{ label: 'aiName', value: 'aiCode' }" optionFilterProp="aiName"-->
        <!-- <a-select
            v-model:value="data.formData.BAH"
            show-search
            mode="multiple"
            placeholder="请选择注册证号/备案号"
            :options="BAHoptions"
            :field-names="{ label: 'nameAndCode', value: 'id' }"
            labelInValue
            @change="handleChange"
          ></a-select>
        </a-form-item> -->
        <!-- <a-form-item label="注册产品标识：" required name="productCode">
          <a-input
            v-model:value="data.formData.productCode"
            placeholder="请输入注册产品标识"
          />
        </a-form-item> -->
        <a-form-item label="产品规格：" name="productSpec">
          <a-input
            v-model:value="data.formData.productSpec"
            placeholder="请输入产品规格"
          />
        </a-form-item>
        <a-form-item label="GTIN：" name="gtin">
          <a-input
            v-model:value="data.formData.gtin"
            placeholder="请输入GTIN"
          />
        </a-form-item>

        <!-- <a-form-item
          v-for="(item, index) in data.gtins"
          :label="number_zh[index] + '级码'"
          :key="index"
          name="productCode"
        >
          <a-input
            v-model:value="item.codeLevel"
            placeholder="请输入注册产品标识"
          />
          <a-input-group>
            <a-row :gutter="8">
              <a-col :span="7">
                <a-input
                  v-model:value="item.codeLevel"
                  placeholder="请输入包装指示符"
                />
              </a-col>
              <a-col :span="7">
                <a-input
                  v-model:value="item.packageLevel"
                  placeholder="请输入产品代码"
                />
              </a-col>
              <a-col :span="14">
                <a-input v-model:value="item.gtin" placeholder="请输入gtin" />
              </a-col>
              <a-col
                :span="3"
                v-if="data.gtins.length - 1 == index"
                style="
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                "
              >
                <PlusCircleOutlined style="font-size: 24px" @click="addGtin" />
                <MinusCircleOutlined
                  v-if="index > 0"
                  style="font-size: 24px"
                  @click="removeGtin"
                />
              </a-col>
            </a-row>
          </a-input-group>
        </a-form-item> -->
        <a-form-item label="备注：" name="remark">
          <a-input
            v-model:value="data.formData.remark"
            placeholder="请输入备注"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {
  SearchOutlined,
  SyncOutlined,
  PlusOutlined,
  UploadOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  RetweetOutlined,
  SmileOutlined,
  DownOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import Pagination from "@/components/Pagination";
import moment from "moment";
import dayjs from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import {
  computed,
  onMounted,
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
  toRaw,
  toRefs,
} from "vue";
import { flbmOptions } from "../../js/flbm";
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf("day");
};
const columns = [
  {
    title: "序号",
    customRender: ({ text, record, index }) => `${index + 1}`,
  },
  // {
  //   title: "注册企业名称",
  //   dataIndex: "factoryName",
  //   key: "factoryName",
  // },
  {
    title: "产品名称",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "产品规格",
    key: "productSpec",
    dataIndex: "productSpec",
  },
  {
    title: "GTIN",
    dataIndex: "gtin",
    key: "gtin",
  },
  {
    title: "注册备案号",
    key: "BAH",
    dataIndex: "BAH",
  },
  {
    title: "创建时间",
    key: "createTime",
    dataIndex: "createTime",
    customRender: (text) => {
      return moment(text.text).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "备注",
    key: "remark",
    dataIndex: "remark",
  },
  {
    title: "操作者",
    key: "operatorName",
    dataIndex: "operatorName",
  },
  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    // slots: { customRender: "action" },
  },
];
const rules = {
  productName: [
    {
      required: true,
      message: "请输入产品名字",
      trigger: "blur",
    },
  ],
  // udiConfig: [
  //   {
  //     required: true,
  //     message: "请选择UDI配置",
  //     trigger: "change",
  //   },
  // ],
  BAH: [
    {
      required: true,
      message: "请选择备案号",
      trigger: "change",
    },
  ],
  // creditCode: [
  //   {
  //     required: true,
  //     message: "请输入注册备案号",
  //     trigger: "blur",
  //   },
  // ],
  productSpec: [
    {
      required: true,
      message: "请输入产品规格",
      trigger: "blur",
    },
  ],
  gtin: [
    {
      required: true,
      message: "请输入GTIN",
      trigger: "blur",
    },
  ],
};

const innerColumns = [
  {
    title: "规格",
    dataIndex: "productSpec",
    key: "productSpec",
  },
  {
    title: "产品代码",
    dataIndex: "productCode",
    key: "productCode",
  },
  {
    title: "GTIN码",
    dataIndex: "gtin",
    key: "gtin",
  },
];
const innerData = [];
for (let i = 0; i < 3; ++i) {
  innerData.push({
    key: i,
    productSpec: "100ml",
    productCode: "产品代码",
    gtin: "0694512445545",
  });
}
export default defineComponent({
  components: {
    SearchOutlined,
    SyncOutlined,
    PlusOutlined,
    UploadOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    RetweetOutlined,
    SmileOutlined,
    DownOutlined,
    Pagination,
    PlusCircleOutlined,
    MinusCircleOutlined,
  },
  setup() {
    const { http } = getCurrentInstance().appContext.config.globalProperties;
    const formRef = ref();
    const addFormRef = ref();
    const visible = ref(false);
    const submitVisible = ref(false);
    const number_zh = [
      "一",
      "二",
      "三",
      "四",
      "五",
      "六",
      "七",
      "八",
      "九",
      "十",
    ];
    const hasSelected = computed(() => data.selectedRowKeys.length > 0);
    const CPBSBMTXMCOptions = ref([
      {
        value: "GS1",
        label: "GS1",
      },
      {
        value: "MA码(中关村)",
        label: "MA码(中关村)",
      },
      {
        value: "AHM(阿里健康)",
        label: "AHM(阿里健康)",
      },
    ]);
    const data = reactive({
      selectedRowKeys: [],
      BAHoptions: [],
      gtins: [
        {
          codeLevel: "",
          packageLevel: "",
          gtin: "",
        },
      ],
      formState: {
        productName: "",
        creditCode: "",
      },
      tableData: [],
      formData: {
        productName: "",
        productSpec: "",
        gtin: "",
        remark: "",
      },
      pagination: {
        pageSize: 10,
        pageNum: 1,
        current: 1,
        total: 1,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
      },
      submitFormData: {
        CPBSBMTXMC: "GS1",
        BSZT: ["1", "2"],
        SFYZCBAYZ: "1",
        SFYBTZJBS: "0",
        SFWBLZTLCP: "0",
        CPLX: "1",
        // CPBSFBRQ:dayjs(new Date(), "YYYY/MM/DD")
      },
      modalTitle: "新增",
    });
    let checkSYDYCPBS = async (rule, value) => {
      if (data.submitFormData.ZXXSDYZSYDYDSL > 1 && !value) {
        return Promise.reject("请输入使用单元产品标识");
      } else {
        return Promise.resolve();
      }
    };
    const submitRules = {
      ZXXSDYCPBS: [
        {
          required: true,
          message: "请输入最小销售单元产品标识",
          trigger: "blur",
        },
      ],
      CPBSBMTXMC: [
        {
          required: true,
          message: "请选择产品标识编码体系",
          trigger: "change",
        },
      ],
      CPBSFBRQ: [
        {
          required: true,
          message: "请选择产品标识发布日期",
          trigger: "change",
          type: "object",
        },
      ],
      ZXXSDYZSYDYDSL: [
        {
          required: true,
          message: "请输入最小销售单元中使用单元的数量",
          trigger: "blur",
        },
      ],
      SYDYCPBS: [
        {
          // required: true,
          // message: "请输入使用单元产品标识",
          validator: checkSYDYCPBS,
          trigger: "blur",
        },
      ],
      BSZT: [
        {
          required: true,
          message: "请至少选择一项标识载体",
          trigger: "change",
          type: "array",
        },
      ],
      SFYZCBAYZ: [
        {
          required: true,
          message: "请选择是否与注册/备案产品标识一致",
          trigger: "change",
        },
      ],
      SFYBTZJBS: [
        {
          required: true,
          message: "请选择是否有本体直接标识",
          trigger: "change",
        },
      ],
      BTCPBSYZXXSDYCPBSSFYZ: [
        {
          required: true,
          message: "请选择是否与最小销售单元产品标识一致",
          trigger: "change",
        },
      ],
      BTCPBS: [
        {
          required: true,
          message: "请输入本体直接标识",
          trigger: "blur",
        },
      ],
      CPMCTYMC: [
        {
          required: true,
          message: "请输入产品名称/通用名称",
          trigger: "blur",
        },
      ],
      GGXH: [
        {
          required: true,
          message: "请输入规格型号",
          trigger: "blur",
        },
      ],
      SFWBLZTLCP: [
        {
          required: true,
          message: "请选择是否为包类/组套类产品",
          trigger: "change",
        },
      ],
      CPMS: [
        {
          required: true,
          message: "请输入产品描述",
          trigger: "blur",
        },
      ],
      CPLX: [
        {
          required: true,
          message: "请选择器械类别",
          trigger: "change",
        },
      ],
      FLBM: [
        {
          required: true,
          message: "请选择分类编码",
          trigger: "change",
          type: "array",
        },
      ],
      BAH: [
        {
          required: true,
          message: "请选择注册证/备案凭证编号",
          trigger: "change",
        },
      ],
      HCHZSB: [
        {
          required: true,
          message: "请选择产品类别",
          trigger: "change",
        },
      ],
      CGZMRAQXGXX: [
        {
          required: true,
          message: "请选择磁共振（MR）安全相关信息",
          trigger: "change",
        },
      ],
      SFBJWYCXSY: [
        {
          required: true,
          message: "请选择是否标记为一次性使用",
          trigger: "change",
        },
      ],
      SFWWJBZ: [
        {
          required: true,
          message: "请选择是否为无菌包装",
          trigger: "change",
        },
      ],
      SYQSFXYJXMJ: [
        {
          required: true,
          message: "请选择使用前是否需要灭菌",
          trigger: "change",
        },
      ],
      SCBSSFBHPH: [
        {
          required: true,
          message: "请选择生产标识是否包含批号",
          trigger: "change",
        },
      ],
      SCBSSFBHXLH: [
        {
          required: true,
          message: "请选择生产标识是否包序列号",
          trigger: "change",
        },
      ],
      SCBSSFBHSCRQ: [
        {
          required: true,
          message: "请选择生产标识是否包生产日期",
          trigger: "change",
        },
      ],
      SCBSSFBHSXRQ: [
        {
          required: true,
          message: "请选择生产标识是否包失效日期",
          trigger: "change",
        },
      ],
    };
    const getBAH = (value) => {
      data.BAH = value;
      console.log(data.BAH);
    };
    const getBSZT = (value) => {
      console.log(value);
      console.log(toRaw(data.submitFormData));
    };
    const onChange = (value, dateString) => {
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
    };
    const addGtin = () => {
      if (data.gtins.length == 10) {
        message.error("超出最大码层级限制");
      } else {
        data.gtins.push({
          codeLevel: "",
          packageLevel: "",
          gtin: "",
        });
      }
    };
    const removeGtin = () => {
      data.gtins.pop();
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const onShowSizeChange = (current, pageSize) => {
      console.log(current, pageSize);
      data.pagination.pageNum = 1;
      data.pagination.pageSize = pageSize;
      getProductList();
    };
    const onPageNumChange = (current, pageSize) => {
      data.pagination.pageNum = current;
      getProductList();
    };
    function getCertificateInfo() {
      http
        .post(
          "/registercertificate/getList",
          Object.assign(toRaw(data.formState), {
            pageSize: 1000,
            pageNum: 1,
          })
        )
        .then((res) => {
          console.log("获取注册证列表", res);
          console.log("res.data.total", res.data.total);
          if (res.data.state) {
            data.BAHoptions = res.data.data;
            data.BAHoptions.map((item) => {
              item.nameAndCode = item.productName + "(" + item.creditCode + ")";
            });
            console.log(data.BAHoptions);
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getProductList() {
      http
        .post(
          "/product/getList",
          Object.assign(toRaw(data.formState), {
            pageSize: data.pagination.pageSize,
            pageNum: data.pagination.pageNum,
          })
        )
        .then((res) => {
          console.log("获取产品列表", res);
          console.log("res.data.total", res.data.total);
          if (res.data.state) {
            data.tableData = res.data.data;
            data.pagination.total = res.data.total;
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    const onSelectChange = (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows);
      data.selectedRowKeys = selectedRowKeys;
    };
    const submit = (text, record, index) => {
      submitVisible.value = true;
    };
    const addInfo = (text, record, index) => {
      record && (data.formData = record);
      record ? (data.modalTitle = "编辑") : (data.modalTitle = "新增");
      visible.value = true;
    };
    const confirm = (e) => {
      delProduct(e.id);
    };
    function resetFormData() {
      return {
        productName: "",
        productSpec: "",
        gtin: "",
        remark: "",
      };
    }
    //选择编码体系
    const CPBSBMTXMCHandleChange = (value) => {
      console.log((data.submitFormData.CPBSBMTXMC = value)); // { key: "lucy", label: "Lucy (101)" }
      console.log(toRaw(data.submitFormData)); // { key: "lucy", label: "Lucy (101)" }
    };
    const cancel = () => {
      data.formData = resetFormData();
    };
    function delProduct(id) {
      http
        .post("/product/delete", { id: id })
        .then((res) => {
          if (res.data.state) {
            message.success(res.data.message);
            getProductList();
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    const handleOk = () => {
      // console.log("----->", addFormRef);
      // console.log(moment().subtract(10, "days").calendar());
      addFormRef.value
        .validate()
        .then(() => {
          if (toRaw(data.formData).id) {
            http.post("/product/update", toRaw(data.formData)).then((res) => {
              if (res.data.state) {
                message.success(res.data.message);
                visible.value = false;
                cancel();
                getProductList();
              } else {
                message.error(res.data.message);
              }
            });
          } else {
            data.loadingNum = 10000;
            console.log(toRaw(data.formData));
            http.post("/product/add", toRaw(data.formData)).then((res) => {
              if (res.data.state) {
                message.success(res.data.message);
                visible.value = false;
                cancel();
                getProductList();
              } else {
                message.error(res.data.message);
              }
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    onMounted(async () => {
      console.log(data.locale);
      getProductList();
      setTimeout(() => {
        getCertificateInfo();
      }, 100);
    });
    return {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      flbmOptions,
      columns,
      addFormRef,
      formRef,
      CPBSBMTXMCOptions,
      data,
      visible,
      submitVisible,
      rules,
      submitRules,
      innerColumns,
      innerData,
      number_zh,
      hasSelected,
      locale,
      onShowSizeChange,
      onPageNumChange,
      disabledDate,
      ...toRefs(data),
      onSelectChange,
      resetForm,
      confirm,
      cancel,
      onChange,
      addInfo,
      submit,
      handleOk,
      cancel,
      CPBSBMTXMCHandleChange,
      getBSZT,
      getBAH,
      addGtin,
      removeGtin,
    };
  },
});
</script>
<style scoped>
/* /deep/ .ant-table-thead > tr > th {
  text-align: center;
} */
.form-container {
  padding: 10px;
  border: solid 1px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
}
</style>
