export const flbmOptions = [
  {
    value: "01",
    label: "01 有源手术器械",
    children: [
      {
        value: "0",
        label: "00 无",
        children: [{ value: "00", label: "00 无" }],
      },
      {
        value: "01",
        label: "01 超声手术设备及附件",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 超声手术设备" },
          { value: "02", label: "02 高强度超声治疗设备" },
          { value: "03", label: "03 超声手术设备附件" },
        ],
      },
      {
        value: "02",
        label: "02 激光手术设备及附件",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 激光手术设备" },
          { value: "02", label: "02 医用激光光纤" },
        ],
      },
      {
        value: "03",
        label: "03 高频/射频手术设备及附件",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 高频手术设备" },
          { value: "02", label: "02 射频消融设备" },
          { value: "03", label: "03 氩保护气凝设备" },
          { value: "04", label: "04 高频/射频用电极及导管" },
          { value: "05", label: "05 射频消融设备用灌注泵" },
        ],
      },
      {
        value: "04",
        label: "04 微波手术设备",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 微波手术设备" },
        ],
      },
      {
        value: "05",
        label: "05 冷冻手术设备及附件",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 冷冻手术设备" },
          { value: "02", label: "02 冷冻消融针及导管" },
        ],
      },
      {
        value: "06",
        label: "06 冲击波手术设备",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 冲击波碎石机" },
        ],
      },
      {
        value: "07",
        label: "07 手术导航、控制系统",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 手术导航系统" },
          { value: "02", label: "02 手术定位系统" },
          { value: "03", label: "03 手术控制系统" },
        ],
      },
      {
        value: "08",
        label: "08 手术照明设备",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 手术无影灯" },
          { value: "02", label: "02 手术辅助照明灯" },
        ],
      },
      {
        value: "09",
        label: "09 内窥镜手术用有源设备",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 内窥镜手术用有源设备" },
        ],
      },
      {
        value: "10",
        label: "10 其他手术设备",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 水刀" },
          { value: "02", label: "02 分离控制盒" },
          { value: "03", label: "03 电动吻合器" },
          { value: "04", label: "04 手术动力系统" },
          { value: "05", label: "05 取、植皮设备" },
        ],
      },
    ],
  },
  {
    value: "02",
    label: "02 无源手术器械",
    children: [
      {
        value: "0",
        label: "00 无",
        children: [{ value: "00", label: "00 无" }],
      },
      {
        value: "01",
        label: "01 手术器械-刀",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 手术刀" },
          { value: "02", label: "02 血管刀" },
          { value: "03", label: "03 备皮刀" },
          { value: "04", label: "04 环切器" },
          { value: "05", label: "05 内窥镜用刀" },
        ],
      },
      {
        value: "02",
        label: "02 手术器械-凿",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 手术凿" },
          { value: "02", label: "02 手术锤" },
        ],
      },
      {
        value: "03",
        label: "03 手术器械-剪",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 组织剪" },
          { value: "02", label: "02 器械剪" },
          { value: "03", label: "03 内窥镜用剪" },
        ],
      },
      {
        value: "04",
        label: "04 手术器械-钳",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 组织钳" },
          { value: "02", label: "02 取样钳" },
          { value: "03", label: "03 分离钳" },
          { value: "04", label: "04 牵引钳" },
          { value: "05", label: "05 异物钳" },
          { value: "06", label: "06 止血钳" },
          { value: "07", label: "07 扩张钳" },
          { value: "08", label: "08 器械钳" },
          { value: "09", label: "09 内窥镜用组织钳" },
          { value: "10", label: "10 内窥镜用取样钳" },
          { value: "11", label: "11 内窥镜用分离钳" },
          { value: "12", label: "12 内窥镜用异物钳" },
          { value: "13", label: "13 内窥镜用器械钳" },
        ],
      },
      {
        value: "05",
        label: "05 手术器械-镊",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 组织镊" },
          { value: "02", label: "02 器械镊" },
        ],
      },
      {
        value: "06",
        label: "06 手术器械-夹",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 闭合夹" },
          { value: "02", label: "02 止血夹" },
          { value: "03", label: "03 器械夹" },
        ],
      },
      {
        value: "07",
        label: "07 手术器械-针",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 缝合针" },
          { value: "02", label: "02 手术针" },
          { value: "03", label: "03 定位针" },
          { value: "04", label: "04 内窥镜取样针" },
        ],
      },
      {
        value: "08",
        label: "08 手术器械-钩",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 手术钩" },
          { value: "02", label: "02 内窥镜用钩" },
        ],
      },
      {
        value: "09",
        label: "09 手术器械-刮匙",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 手术刮匙" },
          { value: "02", label: "02 内窥镜用组织刮匙" },
        ],
      },
      {
        value: "10",
        label: "10 手术器械-剥离器",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 剥离器" },
          { value: "02", label: "02 内窥镜用剥离器" },
        ],
      },
      {
        value: "11",
        label: "11 手术器械-牵开器",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 牵开器" },
          { value: "02", label: "02 压迫器" },
          { value: "03", label: "03 扩张器" },
          { value: "04", label: "04 内窥镜用牵开器" },
          { value: "05", label: "05 内窥镜用气囊扩张器" },
        ],
      },
      {
        value: "12",
        label: "12 手术器械-穿刺导引器",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 穿刺器" },
          { value: "02", label: "02 打孔器" },
          { value: "03", label: "03 输送导引器" },
          { value: "04", label: "04 微创入路装置" },
          { value: "05", label: "05 内窥镜用导引器" },
        ],
      },
      {
        value: "13",
        label: "13 手术器械-吻（缝）合器械及材料",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 吻合器（带钉）" },
          { value: "02", label: "02 吻合器（不带钉）" },
          { value: "03", label: "03 内窥镜用吻（缝）合器械（不带钉）" },
          { value: "04", label: "04 血管缝合装置" },
          { value: "05", label: "05 施夹器" },
          { value: "06", label: "06 可吸收缝合线" },
          { value: "07", label: "07 不可吸收缝合线" },
          { value: "08", label: "08 免缝闭合器械" },
          { value: "09", label: "09 粘合剂" },
          { value: "10", label: "10 粘堵剂" },
        ],
      },
      {
        value: "14",
        label: "14 手术器械-冲吸器",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 冲吸器" },
          { value: "02", label: "02 吸引器" },
        ],
      },
      {
        value: "15",
        label: "15 手术器械-其他器械",
        children: [
          { value: "00", label: "00 无" },
          { value: "01", label: "01 套扎器" },
          { value: "02", label: "02 推结器" },
          { value: "03", label: "03 固位器" },
          { value: "04", label: "04 清洁器" },
          { value: "05", label: "05 测量器" },
          { value: "06", label: "06 保护器" },
          { value: "07", label: "07 植皮器" },
          { value: "08", label: "08 标记器" },
          { value: "09", label: "09 手柄" },
          { value: "10", label: "10 手术锉" },
          { value: "11", label: "11 手术叉" },
          { value: "12", label: "12 手术环" },
          { value: "13", label: "13 试模" },
          { value: "14", label: "14 夹子装置" },
          { value: "15", label: "15 肛门镜" },
          { value: "16", label: "16 内窥镜用推结器" },
          { value: "17", label: "17 内窥镜用细胞刷" },
          { value: "18", label: "18 内窥镜用取石器械" },
          { value: "19", label: "19 内窥镜切口牵开保护器" },
          { value: "20", label: "20 内窥镜用取石球囊导管" },
          { value: "21", label: "21 内窥镜用气囊导管" },
          { value: "22", label: "22 内窥镜用给物器" },
          { value: "23", label: "23 内窥镜用套扎器" },
        ],
      },
    ],
  },
];
