<template>
  <a-layout class="layout-main">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <!-- <div class="logo" /> -->
      <Aside :collapsed="collapsed" @selectMenu="selectMenu" />
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0 16px">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <menu-unfold-outlined
            class="trigger"
            style="padding-right: 20px; font-size: 22px"
            @click="() => (collapsed = !collapsed)"
          />
          <Header :collapsed="collapsed" />
        </div>
      </a-layout-header>
      <a-layout-content>
        <Breadcrumb :keyPath="keyPath" />
        <div class="content">
          <Main />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { MenuUnfoldOutlined } from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
// @ is an alias to /src
import Aside from "./components/aside/Index";
import Header from "./components/Header";
import Main from "./components/Main";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "Home",
  components: {
    Aside,
    Header,
    Main,
    MenuUnfoldOutlined,
    Breadcrumb,
  },

  setup() {
    const { options } = useRouter();
    const routers = options.routes;
    // console.log(routers);
    const data = reactive({
      collapsed: false,
      keyPath: localStorage.getItem("keyPath")
        ? [localStorage.getItem("keyPath")]
        : ["/index"],
    });
    function selectMenu(options) {
      data.keyPath = options;
      localStorage.setItem("keyPath", options);
    }
    return {
      ...toRefs(data),
      selectMenu,
    };
  },
};
</script>
<style lang="scss">
.layout-main {
  height: 100vh;
}
.content {
  margin: 16px;
  background-color: #fff;
  height: calc(100vh - 190px);
  overflow-y: auto;
  padding: 20px 10px;
}
</style>
