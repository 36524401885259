<template>
  <div class="account">
    <div class="form-wrap">
      <div class="text-center title">UDI管理系统</div>
      <a-form
        name="custom-validation"
        ref="formRef"
        :model="data.userInfo"
        :rules="rules"
        layout="vertical"
      >
        <a-form-item label="用户名" ref="loginName" name="loginName">
          <!-- <label>用户名</label> -->
          <a-input
            type="text"
            size="large"
            v-model:value="data.userInfo.loginName"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="密　码" ref="password" name="password">
          <!-- <label>密码</label> -->
          <a-input
            type="password"
            size="large"
            v-model:value="data.userInfo.password"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="submit" html-type="submit" block
            >登录</a-button
          >
        </a-form-item>
      </a-form>
      <div class="text-center fs-12">
        <a href="" class="color-white">忘记密码</a> |
        <a href="" class="color-white">注册</a>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, toRaw, onMounted } from "vue";
import { message } from "ant-design-vue";
// import router from "@/router/index.js";
import { useRouter } from "vue-router";
export default {
  name: "Login",
  setup() {
    const { http } = getCurrentInstance().appContext.config.globalProperties;
    const router = useRouter();
    const formRef = ref();
    const data = reactive({
      formConfig: {
        layout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        },
      },
      userInfo: {
        loginName: "",
        password: "",
      },
    });
    const rules = {
      loginName: [
        {
          required: true,
          message: "请输入用户名",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
      ],
    };
    const submit = async () => {
      formRef.value
        .validate()
        .then(async () => {
          console.log("values", data.userInfo, toRaw(data.userInfo));
          const res = await http.post("/login/login", toRaw(data.userInfo));
          console.log(res);
          if (res.data.state) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
            localStorage.setItem("openKeys", "/primaryData");
            localStorage.setItem("selectedKeys", "/enterprise");
            router.push("/enterprise");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
      // console.log(data.userInfo);
      // message.error("登录失败");
      // const res = await http.post("/enterprise/addEnterprise", data.userInfo);
      // // console.log(res);
      // localStorage.setItem("token", "545665466464");
      // localStorage.setItem("selectedKeys", "/enterprise");
      // // localStorage.setItem("token", "545665466464");
      // router.push("/enterprise");
      // console.log("999");
    };
    onMounted(async () => {
      localStorage.clear();
    });
    return {
      data,
      rules,
      formRef,
      submit,
    };
  },
};
</script>
<style lang="scss" scoped>
.account {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/img/login-background.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 20px;
    padding-bottom: 20px;
  }
  .form-wrap {
    width: 320px;
    color: #fff;
    margin-top: -40px;
    box-shadow: 0 0 10px #fff;
    padding: 40px;
    label {
      display: block;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>
