<template>
  <div class="header">
    <a-dropdown>
      <a class="ant-dropdown-link" @click.prevent>
        <img
          class="avator"
          src="../../../assets/img/avator.jpg"
          alt=""
          srcset=""
        />
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item key="0"> 个人中心 </a-menu-item>
          <a-menu-divider></a-menu-divider>
          <a-menu-item key="1" @click="logout"> 退出 </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import { DownOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
export default {
  name: "Aside",
  props: {
    collapsed: {
      type: Boolean,
    },
  },
  components: {
    DownOutlined,
  },
  setup(props, context) {
    const router = useRouter();
    const logout = () => {
      localStorage.removeItem("token");
      router.push("/");
    };
    return {
      logout,
    };
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  .avator {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
}
</style>
