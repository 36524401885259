<template>
  <div>
    <div class="table-bar-wrapper">
      <a-form :model="data.formState" ref="formRef">
        <a-row :gutter="16">
          <!-- <a-col class="gutter-row" :span="5">
            <a-form-item label="注册企业名称" name="enterpriseName">
              <a-input v-model:value="data.formState.enterpriseName" />
            </a-form-item>
          </a-col> -->
          <a-col class="gutter-row" :span="5">
            <a-form-item label="注册产品名称" name="productName">
              <a-input v-model:value.trim="data.formState.productName" />
            </a-form-item>
          </a-col>
          <a-col class="gutter-row" :span="5">
            <a-form-item label="注册备案号" name="creditCode">
              <a-input v-model:value.trim="data.formState.creditCode" />
            </a-form-item>
          </a-col>
          <a-col class="gutter-row" :span="5">
            <a-form-item label="创建时间" name="date">
              <a-range-picker
                :locale="locale"
                :placeholder="['开始', '结束']"
                @change="onChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-space>
              <a-button type="primary" @click="getCertificateInfo">
                <template #icon><SearchOutlined /></template>
                查询
              </a-button>
              <a-button @click="resetForm">
                <template #icon><SyncOutlined /></template>
                重置
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-button type="primary" @click="addInfo">
        <template #icon><PlusOutlined /></template>
        新建
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data.tableData"
      :rowKey="(record) => record.id"
      :pagination="false"
    >
      <template #action="{ text, record, index }">
        <a-space>
          <a @click="addInfo(text, record, index)">编辑 </a>
          <a-popconfirm
            title="确定删除这条信息吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(record)"
            @cancel="cancel(record)"
          >
            <a > 删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
    <!-- <Pagination :pagination="data.pagination" /> -->
    <a-pagination
      style="text-align: right; padding-top: 20px"
      v-model:current="data.pagination.pageNum"
      v-model:pageSize="data.pagination.pageSize"
      :page-size-options="data.pagination.pageSizeOptions"
      show-size-changer
      @showSizeChange="onShowSizeChange"
      @change="onPageNumChange"
      :total="data.pagination.total"
    >
      <template #buildOptionText="props">
        <span>{{ props.value }}条/页</span>
      </template></a-pagination
    >
    <a-modal
      v-model:visible="visible"
      width="800px"
      :title="data.modalTitle + '注册证/备案信息'"
      cancelText="取消"
      okText="确定"
      @ok="handleOk"
      @cancel="cancel"
    >
      <a-form
        ref="addFormRef"
        :rules="rules"
        :model="data.formData"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="注册产品名称：" required name="productName">
          <a-input
            v-model:value="data.formData.productName"
            placeholder="请输入注册产品名称"
          />
        </a-form-item>
        <a-form-item label="注册人/备案人：" required name="legalPerson">
          <a-input
            v-model:value="data.formData.legalPerson"
            placeholder="请输入注册人/备案人"
          />
        </a-form-item>
        <a-form-item label="注册备案号：" required name="creditCode">
          <a-input
            v-model:value="data.formData.creditCode"
            placeholder="请输入注册备案号"
          />
        </a-form-item>
        <!-- <a-form-item label="批准日期：" required name="aproveDate"> -->
        <!-- <a-input
            v-model:value="data.formData.aproveDate"
            placeholder="请选择批准日期"
          /> -->
        <a-form-item name="aproveDate" label="批准日期：">
          <a-date-picker
            :locale="locale"
            v-model:value="data.formData.aproveDate"
            value-format="YYYY-MM-DD"
            style="width: 100%"
            placeholder="请选择批准日期"
          />
        </a-form-item>
        <a-form-item name="effectiveDate" label="生效日期：">
          <a-date-picker
            :locale="locale"
            v-model:value="data.formData.effectiveDate"
            value-format="YYYY-MM-DD"
            style="width: 100%"
            placeholder="请选择生效日期"
          />
        </a-form-item>
        <a-form-item name="expiringDate" label="失效日期：">
          <a-date-picker
            :locale="locale"
            v-model:value="data.formData.expiringDate"
            value-format="YYYY-MM-DD"
            style="width: 100%"
            placeholder="请选择失效日期"
          />
        </a-form-item>
        <!-- </a-form-item> -->
        <a-form-item label="注册产品标识：" name="productCode">
          <a-input
            v-model:value="data.formData.productCode"
            placeholder="请输入注册产品标识"
          />
        </a-form-item>
        <a-form-item label="备注：" name="remark">
          <a-input
            v-model:value="data.formData.remark"
            placeholder="请输入备注"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {
  SearchOutlined,
  SyncOutlined,
  PlusOutlined,
  SmileOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import Pagination from "@/components/Pagination";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  toRaw,
} from "vue";
const columns = [
  {
    title: "序号",
    customRender: ({ text, record, index }) => `${index + 1}`,
  },
  {
    title: "注册产品名称",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "注册备案号",
    key: "creditCode",
    dataIndex: "creditCode",
  },
  {
    title: "批准日期",
    key: "aproveDate",
    dataIndex: "aproveDate",
    customRender: (text) => {
      return moment(text.text).format("YYYY-MM-DD");
    },
  },
  {
    title: "失效日期",
    key: "expiringDate",
    dataIndex: "expiringDate",
    customRender: (text) => {
      return moment(text.text).format("YYYY-MM-DD");
    },
  },
  {
    title: "备注",
    key: "remark",
    dataIndex: "remark",
  },
  // {
  //   title: "操作者",
  //   key: "operator",
  //   dataIndex: "operator",
  // },
  {
    title: "操作",
    slots: { customRender: "action" },
  },
];
const rules = {
  productName: [
    {
      required: true,
      message: "请输入注册产品名称",
      trigger: "blur",
    },
  ],
  legalPerson: [
    {
      required: true,
      message: "请输入注册人/备案人",
      trigger: "blur",
    },
  ],
  creditCode: [
    {
      required: true,
      message: "请输入注册备案号",
      trigger: "blur",
    },
  ],
  aproveDate: [
    {
      required: true,
      message: "请输入批准日期",
      trigger: "change",
    },
  ],
};
// const pagination = {
//   pageSize: ref(10),
//   pageNum: 1,
//   current: ref(2),
//   total: ref(0),
//   pageSizeOptions: ["10", "20"],
// };
export default defineComponent({
  components: {
    SearchOutlined,
    SyncOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    Pagination,
  },
  setup() {
    const { http } = getCurrentInstance().appContext.config.globalProperties;
    const formRef = ref();
    const addFormRef = ref();
    const visible = ref(false);
    const data = reactive({
      formState: {
        productName: "",
        creditCode: "",
      },
      pagination: {
        pageSize: 10,
        pageNum: 1,
        current: 1,
        total: 1,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
      },
      formData: {
        productName: "",
        legalPerson: "",
        creditCode: "",
        productCode: "",
        aproveDate: "",
        effectiveDate: "",
        expiringDate: "",
        remark: "",
      },
      modalTitle: "新增",
      tableData: [],
    });
    const onChange = (value, dateString) => {
      console.log("Selected Time: ", moment(value));
      console.log("Formatted Selected Time: ", dateString);
      data.formState.rangeDate = dateString;
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const addInfo = (text, record, index) => {
      record && (data.formData = record);
      if (record) {
        data.formData.aproveDate = moment(toRaw(text).aproveDate).format(
          "YYYY-MM-DD"
        );
        data.formData.effectiveDate = moment(toRaw(text).effectiveDate).format(
          "YYYY-MM-DD"
        );
        data.formData.expiringDate = moment(toRaw(text).expiringDate).format(
          "YYYY-MM-DD"
        );
      }
      record ? (data.modalTitle = "编辑") : (data.modalTitle = "新增");
      visible.value = true;
    };
    function delCertificate(id) {
      http
        .post("/registercertificate/delete", { id: id })
        .then((res) => {
          if (res.data.state) {
            message.success(res.data.message);
            getCertificateInfo();
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    const confirm = (e) => {
      delCertificate(e.id);
    };
    function getCertificateInfo() {
      http
        .post(
          "/registercertificate/getList",
          Object.assign(toRaw(data.formState), {
            pageSize: data.pagination.pageSize,
            pageNum: data.pagination.pageNum,
          })
        )
        .then((res) => {
          console.log("获取注册证列表", res);
          console.log("res.data.total", res.data.total);
          if (res.data.state) {
            data.tableData = res.data.data;
            data.pagination.total = res.data.total;
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function resetFormData() {
      return {
        productName: "",
        legalPerson: "",
        creditCode: "",
        aproveDate: "",
        effectiveDate: "",
        expiringDate: "",
        productCode: "",
        remark: "",
      };
    }
    const cancel = () => {
      data.formData = resetFormData();
    };
    const onShowSizeChange = (current, pageSize) => {
      console.log(current, pageSize);
      data.pagination.pageNum = 1;
      data.pagination.pageSize = pageSize;
      getCertificateInfo();
    };
    const onPageNumChange = (current, pageSize) => {
      data.pagination.pageNum = current;
      getCertificateInfo();
    };
    const handleOk = () => {
      data.pagination.total = ref(500);
      addFormRef.value
        .validate()
        .then(() => {
          if (toRaw(data.formData).id) {
            http
              .post("/registercertificate/update", toRaw(data.formData))
              .then((res) => {
                if (res.data.state) {
                  message.success(res.data.message);
                  visible.value = false;
                  cancel();
                  getCertificateInfo();
                } else {
                  message.error(res.data.message);
                }
              });
          } else {
            data.loadingNum = 10000;
            http
              .post("/registercertificate/add", toRaw(data.formData))
              .then((res) => {
                if (res.data.state) {
                  message.success(res.data.message);
                  visible.value = false;
                  getCertificateInfo();
                } else {
                  message.error(res.data.message);
                }
              });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    onMounted(async () => {
      getCertificateInfo();
    });
    return {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns,
      addFormRef,
      formRef,
      data,
      // pagination,
      visible,
      rules,
      locale,
      onShowSizeChange,
      onPageNumChange,
      getCertificateInfo,
      resetForm,
      confirm,
      cancel,
      onChange,
      addInfo,
      handleOk,
      cancel,
    };
  },
});
</script>
<style scoped>
/* /deep/ .ant-table-thead > tr > th {
  text-align: center;
} */
</style>
