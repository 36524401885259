<template>
  <div>
    <div class="table-bar-wrapper">
      <a-form :model="data.formState" ref="formRef">
        <a-row :gutter="16">
          <a-col class="gutter-row" :span="5">
            <a-form-item label="配置名字" name="configName">
              <a-input v-model:value.trim="data.formState.configName" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-space>
              <a-button type="primary" @click="getUdiconfigInfo">
                <template #icon><SearchOutlined /></template>
                查询
              </a-button>
              <a-button @click="resetForm">
                <template #icon><SyncOutlined /></template>
                重置
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-button type="primary" @click="addInfo">
        <template #icon><PlusOutlined /></template>
        新建
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data.tableData"
      :pagination="false"
    >
      <template #action="{ text, record, index }">
        <a-space>
          <a @click="addInfo(text, record, index)">编辑 </a>
          <a-popconfirm
            title="确定删除这条信息吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(record)"
            @cancel="cancel(record)"
          >
            <a> 删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
    <a-pagination
      style="text-align: right; padding-top: 20px"
      v-model:current="data.pagination.pageNum"
      v-model:pageSize="data.pagination.pageSize"
      :page-size-options="data.pagination.pageSizeOptions"
      show-size-changer
      @showSizeChange="onShowSizeChange"
      @change="onPageNumChange"
      :total="data.pagination.total"
    >
      <template #buildOptionText="props">
        <span>{{ props.value }}条/页</span>
      </template></a-pagination
    >
    <a-modal
      v-model:visible="visible"
      width="800px"
      :title="data.modalTitle + '标识符'"
      cancelText="取消"
      okText="确定"
      @ok="handleOk"
      @cancel="cancel"
    >
      <a-form
        ref="addFormRef"
        :rules="rules"
        :model="data.formData"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="配置名字：" required name="configName">
          <a-input
            v-model:value.trim="data.formData.configName"
            placeholder="请输入配置名字"
          />
        </a-form-item>
        <a-form-item label="指示符：" required name="aiCodes">
          <a-select
            v-model:value="data.formData.aiCodes"
            show-search
            mode="multiple"
            placeholder="请选择指示符"
            :options="data.options"
            :field-names="{ label: 'aiName', value: 'aiCode' }"
            optionFilterProp="aiName"
            labelInValue
            @change="handleChange"
          ></a-select>
        </a-form-item>
        <a-form-item label="解释字符：" name="explains">
          <a-input
            disabled
            v-model:value.trim="data.formData.explains"
            placeholder="解释字符"
          />
        </a-form-item>
        <a-form-item label="备注：" name="remark">
          <a-input
            v-model:value.trim="data.formData.remark"
            placeholder="请输入备注"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {
  SearchOutlined,
  SyncOutlined,
  PlusOutlined,
  SmileOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import Pagination from "@/components/Pagination";
import moment from "moment";
import {
  defineComponent,
  reactive,
  ref,
  toRaw,
  onMounted,
  getCurrentInstance,
} from "vue";
const columns = [
  {
    title: "序号",
    customRender: ({ text, record, index }) => `${index + 1}`,
  },
  {
    title: "配置名字",
    dataIndex: "configName",
    key: "configName",
  },
  {
    title: "解释字符",
    key: "explains",
    dataIndex: "explains",
  },
  {
    title: "备注",
    key: "remark",
    dataIndex: "remark",
  },
  {
    title: "创建时间",
    key: "createTime",
    dataIndex: "createTime",
    customRender: (text) => {
      return moment(text.text).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "操作者",
    key: "operatorName",
    dataIndex: "operatorName",
  },
  {
    title: "操作",
    slots: { customRender: "action" },
  },
];
const rules = {
  configName: [
    {
      required: true,
      message: "请输入配置名字",
      trigger: "blur",
    },
  ],
  aiCodes: [
    {
      required: true,
      message: "请选择指示符",
      trigger: "blur",
    },
  ],
};
const pagination = {
  pageSize: ref(10),
  pageNum: 1,
  current: ref(2),
  total: ref(50),
  pageSizeOptions: ["10", "20"],
};
export default defineComponent({
  components: {
    SearchOutlined,
    SyncOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    Pagination,
  },
  setup() {
    const { http } = getCurrentInstance().appContext.config.globalProperties;
    const formRef = ref();
    const addFormRef = ref();
    const visible = ref(false);
    const data = reactive({
      formState: {},
      formData: {
        explains: "",
      },
      tableData: [],
      modalTitle: "新增",
      pagination: {
        pageSize: 10,
        pageNum: 1,
      },
      options: [],
    });
    const onChange = (value, dateString) => {
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
    };
    const resetForm = () => {
      console.log("111", formRef);
      formRef.value.resetFields();
    };
    const addInfo = (text, record, index) => {
      console.log(toRaw(record));
      console.log(toRaw(data.options));
      if (record && !Array.isArray(record.aiCodes)) {
        data.formData = record;
        let arryTemp = [];
        record.aiNames.split(",").forEach((item, index) => {
          arryTemp.push({
            label: item,
            originLabel: item,
            value: record.aiCodes.split(",")[index],
            key: record.aiCodes.split(",")[index],
            option: data.options[index],
          });
        });
        data.formData.aiCodes = arryTemp;
        console.log(toRaw(data.formData));
      } else if (record && Array.isArray(record.aiCodes)) {
        data.formData = record;
        data.formData.aiCodes = toRaw(record.aiCodes);
      }
      record ? (data.modalTitle = "编辑") : (data.modalTitle = "新增");
      visible.value = true;
    };
    const confirm = (e) => {
      delProductFlag(e.id);
    };
    function resetFormData() {
      return {
        productName: "",
        legalPerson: "",
        creditCode: "",
        productCode: "",
        remark: "",
      };
    }
    function handleChange(value) {
      data.formData.explains = "";
      value.forEach((item) => {
        data.formData.explains += item.value + "{" + item.label + "}";
      });
    }
    function delProductFlag(id) {
      http
        .post("/udiconfig/delete", { id: id })
        .then((res) => {
          console.log("获取标识符列表", res);
          console.log("res.data.total", res.data.total);
          if (res.data.state) {
            getUdiconfigInfo();
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getProductFlagInfo() {
      http
        .post("/identification/getList", {
          aiName: "",
          pageSize: 100,
          pageNum: 1,
        })
        .then((res) => {
          if (res.data.state) {
            data.options = res.data.data;
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getUdiconfigInfo() {
      http
        .post(
          "/udiconfig/getList",
          Object.assign(toRaw(data.formState), {
            pageSize: data.pagination.pageSize,
            pageNum: data.pagination.pageNum,
          })
        )
        .then((res) => {
          if (res.data.state) {
            data.tableData = res.data.data;
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    const cancel = () => {
      data.formData = resetFormData();
    };
    const handleOk = () => {
      pagination.total = ref(500);
      addFormRef.value
        .validate()
        .then(() => {
          if (toRaw(data.formData).id) {
            http.post("/udiConfig/update", toRaw(data.formData)).then((res) => {
              if (res.data.state) {
                message.success(res.data.message);
                visible.value = false;
                cancel();
                getUdiconfigInfo();
              } else {
                message.error(res.data.message);
              }
            });
          } else {
            data.loadingNum = 10000;
            console.log(toRaw(data.formData));
            http.post("/udiConfig/add", toRaw(data.formData)).then((res) => {
              if (res.data.state) {
                message.success(res.data.message);
                visible.value = false;
                getUdiconfigInfo();
              } else {
                message.error(res.data.message);
              }
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    onMounted(async () => {
      setTimeout(() => {
        getProductFlagInfo();
      }, 200);
      getUdiconfigInfo();
    });
    return {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns,
      addFormRef,
      formRef,
      data,
      pagination,
      visible,
      rules,
      resetForm,
      handleChange,
      confirm,
      cancel,
      onChange,
      addInfo,
      handleOk,
      cancel,
      getUdiconfigInfo,
    };
  },
});
</script>
<style scoped>
/* /deep/ .ant-table-thead > tr > th {
  text-align: center;
} */
</style>
