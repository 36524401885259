<template>
  <a-form
    ref="formRef"
    :model="data.formState"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-item label="企业名字：" required name="enterpriseName">
      <a-input
        v-model:value="data.formState.enterpriseName"
        placeholder="请输入企业名字"
        :disabled="data.disabled"
      />
    </a-form-item>
    <a-form-item label="企业类型：" name="enterpriseType">
      <a-select
        v-model:value="data.formState.enterpriseType"
        :disabled="data.disabled"
        placeholder="请选择企业类型"
      >
        <a-select-option value="注册人">注册人</a-select-option>
        <a-select-option value="代理人">代理人</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="统一社会信用代码：" required name="creditCode">
      <a-input
        v-model:value="data.formState.creditCode"
        placeholder="请输入统一社会信用代码"
        :disabled="data.disabled"
      />
    </a-form-item>
    <a-form-item label="法人：" required name="legalPerson">
      <a-input
        v-model:value="data.formState.legalPerson"
        :disabled="data.disabled"
        placeholder="请输入法人"
      />
    </a-form-item>
    <a-form-item label="厂家代码：" name="manufacturersCode">
      <a-input
        v-model:value="data.formState.manufacturersCode"
        placeholder="请输入厂家代码"
        :disabled="data.disabled"
      />
    </a-form-item>
    <a-form-item label="备注：" name="remark">
      <a-input
        v-model:value="data.formState.remark"
        :disabled="data.disabled"
        placeholder="请输入备注"
      />
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button v-if="data.disabled" block type="primary" @click="edit"
        >修改</a-button
      >
      <a-button
        v-else
        block
        type="primary"
        :loading="data.loadingNum"
        @click="onSubmit"
        >保存</a-button
      >
    </a-form-item>
  </a-form>
</template>
<script>
import { message } from "ant-design-vue";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  toRefs,
  toRaw,
  toRef,
} from "vue";
export default defineComponent({
  setup() {
    const { http } = getCurrentInstance().appContext.config.globalProperties;
    const formRef = ref();
    const data = reactive({
      formState: {
        enterpriseName: "", //企业名字
        enterpriseType: undefined, //企业类型
        creditCode: "", //统一社会信用代码
        legalPerson: "", //法人
        manufacturersCode: "", //厂家代码
        remark: "", //备注
      },
      disabled: true,
      loadingNum: 0,
    });
    const rules = {
      enterpriseName: [
        {
          required: true,
          message: "请输入企业名字",
          trigger: "blur",
        },
      ],
      enterpriseType: [
        {
          required: true,
          message: "请选择企业类型",
          trigger: "change",
        },
      ],
      creditCode: [
        {
          required: true,
          message: "请输入统一社会信用代码",
          trigger: "blur",
        },
      ],
      legalPerson: [
        {
          required: true,
          message: "请输入法人名字",
          trigger: "blur",
        },
      ],
    };
    const edit = () => {
      data.disabled = !data.disabled;
      console.log(data.disabled);
    };
    const onSubmit = () => {
      console.log(this);
      formRef.value
        .validate()
        .then(async () => {
          data.loadingNum = 10000;
          const res = await http.post(
            "/enterprise/update",
            toRaw(data.formState)
          );
          console.log(res);
          if (res.sqlMessage && res.errno) {
            message.error(res.sqlMessage);
          } else {
            message.success(res.data.message);
            data.disabled = true;
            data.loadingNum = 0;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    onMounted(async () => {
      const res = await http.post("/enterprise/getInfo", {
        id: JSON.parse(localStorage.getItem("userInfo")).id,
      });
      data.formState = res.data[0];
    });
    return {
      formRef,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      data,
      rules,
      onSubmit,
      resetForm,
      edit,
    };
  },
});
</script>
