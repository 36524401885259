<template>
  <div class="breadcrumb">
    <a-breadcrumb>
      <!-- <a-breadcrumb-item v-for="(item, index) in arr" :key="item"
        ><a :href="index === arr.length - 1 ? '#' : item.path">{{
          item.title
        }}</a></a-breadcrumb-item
      > -->
      <a-breadcrumb-item v-for="item in arr" :key="item"
        ><span>{{ item.title }}</span></a-breadcrumb-item
      >
    </a-breadcrumb>
    <div class="title">{{ arr[arr.length - 1].title }}</div>
  </div>
</template>
<script>
import { reactive, watchEffect, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  Name: "",
  props: {
    keyPath: {
      type: Array,
    },
  },

  setup(props) {
    const data = reactive({
      arr: [],
    });
    const { options } = useRouter();
    const routers = options.routes;
    const test = () => {
      // console.log("面包屑组件加载时就执行一次");
    };
    watchEffect(() => {
      test();
      data.arr = [];
      let datas = props.keyPath;
      if (datas.length === 1) {
        datas = datas[0].split(",");
      }
      datas.forEach((item) => {
        routers.forEach((router) => {
          if (item === router.path) {
            data.arr.push({ title: router.meta.title, path: router.path });
          }
          if (router.children) {
            router.children.forEach((childItem) => {
              if (item === childItem.path) {
                data.arr.push({
                  title: childItem.meta.title,
                  path: childItem.path,
                });
                // console.log(childItem);
              }
            });
          }
        });
      });
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  padding: 16px;
  background-color: #fff;
  height: 90px;
  border-top: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
}
.title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
}
</style>
