<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
// 默认语言为 en-US，如果你需要设置其他语言，推荐在入口文件全局设置 locale
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { defineComponent } from "vue";
dayjs.locale("zh-cn");
export default defineComponent({
  setup() {
    return {
      locale,
    };
  },
});
</script>
<style lang="scss">
.ant-btn {
  border-radius: 4px !important;
}
::-webkit-scrollbar {
  width: 1px; /*对垂直流动条有效*/
}
</style>
