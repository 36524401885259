<template>
  <a-sub-menu :key="menu.path">
    <template #title>
      <span class="anticon">
        <i
          class="icon icon-size-21 mb--5 mr-4"
          :class="menu.meta && menu.meta.icon"
        ></i> </span
      ><span>{{ menu.meta && menu.meta.title }}</span>
    </template>
    <template v-for="child in menu.children" :key="child.path">
      <!-- 不存在子级菜单 -->
      <a-menu-item v-if="!child.children" :key="child.path">
        <router-link class="pl-16" :to="child.path">{{
          child.meta && child.meta.title
        }}</router-link>
      </a-menu-item>
      <!-- 存在子级菜单 -->
      <Menu v-else :menu="child" :key="item.path" />
    </template>
  </a-sub-menu>
</template>

<script>
export default {
  name: "Menu",
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {},
};
</script>
<style lang="scss" scoped>
.ant-menu-title-content {
  padding-left: 10px;
}
.pl-16 {
  padding-left: 16px;
}
i.icon {
  opacity: 0.6;
}
.ant-menu-item-selected {
  i.icon {
    opacity: 1;
  }
}
.ant-menu-submenu-open {
  > div i.icon {
    opacity: 1;
  }
}
</style>
