<template>
  <a-pagination
    style="text-align: right; padding-top: 20px"
    show-size-changer
    v-model:current="current"
    v-model:pageSize="pageSize"
    v-model:total="total"
    :page-size-options="pageSizeOptions"
    @showSizeChange="onShowSizeChange"
  >
    <template #buildOptionText="props">
      <span>{{ props.value }}条/页</span>
    </template>
  </a-pagination>
</template>
<script>
import { defineComponent, ref, watch, watchEffect } from "vue";

export default defineComponent({
  props: {
    pagination: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    let pageSize = props.pagination.pageSize;
    let current = props.pagination.current;
    let total = props.pagination.total;
    let pageSizeOptions = props.pagination.pageSizeOptions;
    const onShowSizeChange = (current, pageSize) => {
      console.log(current, pageSize);
    };
    watch(total, () => {
      console.log("total", total.value);
    });
    watch(pageSize, () => {
      console.log("pageSize", pageSize.value);
    });
    watch(current, () => {
      console.log("current", current.value);
    });
    return {
      pageSize,
      current,
      total,
      pageSizeOptions,
      onShowSizeChange,
    };
  },
});
</script>
