import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import Login from "../views/Account/Login.vue";
// import Dashboard from "../views/Dashboard/Index.vue";
import Layout from "../views/Layout/Index.vue";
import Role from "../views/Admin/Role.vue";
import User from "../views/Admin/User.vue";
// import ProductList from "../views/BasicData/ProductList.vue";
//首页 Welcome
import Welcome from "../views/Index/Welcome.vue";
//基础信息管理
import Enterprise from "../views/BasicData/Enterprise.vue";
import RegisterCertificate from "../views/BasicData/RegisterCertificate.vue";
import Product from "../views/BasicData/Product.vue";
import ProductFlag from "../views/BasicData/ProductFlag.vue";
import ProductUdiConfig from "../views/BasicData/ProductUdiConfig.vue";
//UDI管理
import UDIRecord from "../views/udiManage/UDIRecord.vue";
import UDICorrelation from "../views/udiManage/UDICorrelation.vue";
import UDIReplaceRecord from "../views/udiManage/UDIReplaceRecord.vue";
//发货管理
import CustomerList from "../views/shipmentsManage/customerList.vue";
import stock from "../views/WareHouse/stock.vue";
import delivery from "../views/WareHouse/delivery.vue";
import exwarehouse from "../views/WareHouse/exwarehouse.vue";
const routes = [
  {
    path: "/",
    name: "Login",
    hidden: true,
    meta: {
      title: "登录",
    },
    component: Login,
  },
  {
    path: "/index",
    name: "Index",
    meta: {
      title: "首页",
      icon: "icon-aside-home",
    },
    component: Layout,
    children: [
      {
        path: "/welcome",
        name: "Welcome",
        meta: {
          title: "首页",
          icon: "icon-aside-home",
        },
        component: Welcome,
      },
    ],
  },
  {
    path: "/primaryData",
    name: "PrimaryData",
    meta: {
      title: "基础数据管理",
      icon: "icon-aside-home",
    },
    component: Layout,
    children: [
      {
        path: "/enterprise",
        name: "Enterprise",
        meta: {
          title: "企业信息",
          icon: "icon-aside-home",
        },
        component: Enterprise,
      },
      {
        path: "/registerCertificate",
        name: "RegisterCertificate",
        meta: {
          title: "注册证/备案信息",
          icon: "icon-aside-home",
        },
        component: RegisterCertificate,
      },
      {
        path: "/product",
        name: "Product",
        meta: {
          title: "产品管理",
          icon: "icon-aside-home",
        },
        component: Product,
      },
      {
        path: "/productFlag",
        name: "ProductFlag",
        meta: {
          title: "标识符管理",
          icon: "icon-aside-home",
        },
        component: ProductFlag,
      },
      {
        path: "/productUdiConfig",
        name: "ProductUdiConfig",
        meta: {
          title: "UDI配置管理",
          icon: "icon-aside-home",
        },
        component: ProductUdiConfig,
      },
    ],
  },
  {
    path: "/UDIManager",
    name: "UDIManager",
    meta: {
      title: "UDI管理",
      icon: "icon-aside-home",
    },
    component: Layout,
    children: [
      {
        path: "/udiRecord",
        name: "UDIRecord",
        meta: {
          title: "UDI生成记录",
          icon: "icon-aside-home",
        },
        component: UDIRecord,
      },
      {
        path: "/udiCorrelation",
        name: "UDICorrelation",
        meta: {
          title: "UDI关联记录",
          icon: "icon-aside-home",
        },
        component: UDICorrelation,
      },
      {
        path: "/udiReplaceRecord",
        name: "UDIReplaceRecord",
        meta: {
          title: "UDI置换记录",
          icon: "icon-aside-home",
        },
        component: UDIReplaceRecord,
      },
    ],
  },
  // {
  //   path: "/deliveryManagement",
  //   name: "DeliveryManagement",
  //   meta: {
  //     title: "发货管理",
  //     icon: "icon-aside-home",
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/customerList",
  //       name: "CustomerList",
  //       meta: {
  //         title: "客户管理",
  //         icon: "icon-aside-home",
  //       },
  //       component: CustomerList,
  //     },
  //     {
  //       path: "/sendRecord",
  //       name: "SendRecord",
  //       meta: {
  //         title: "发货记录",
  //         icon: "icon-aside-home",
  //       },
  //       component: Role,
  //     },
  //     {
  //       path: "/returnRecord",
  //       name: "ReturnRecord",
  //       meta: {
  //         title: "退货记录",
  //         icon: "icon-aside-home",
  //       },
  //       component: Role,
  //     },
  //   ],
  // },
  {
    path: "/wareHouse",
    name: "WareHouse",
    meta: {
      title: "仓储管理",
      icon: "icon-aside-admin",
    },
    component: Layout,
    children: [
      {
        path: "/stock",
        name: "Stock",
        meta: {
          title: "库存查询",
          icon: "icon-aside-home",
        },
        component: stock,
      },
      {
        path: "/exwarehouse",
        name: "Exwarehouse",
        meta: {
          title: "入库记录",
          icon: "icon-aside-home",
        },
        component: exwarehouse,
      },
      {
        path: "/delivery",
        name: "Delivery",
        meta: {
          title: "发货记录",
          icon: "icon-aside-home",
        },
        component: delivery,
      },
    ],
  },
  {
    path: "/adminIndex",
    name: "AdminIndex",
    meta: {
      title: "系统管理",
      icon: "icon-aside-admin",
    },
    component: Layout,
    children: [
      {
        path: "/myInfo",
        name: "myInfo",
        meta: {
          title: "个人中心",
          icon: "icon-aside-home",
        },
        component: Role,
      },
      // {
      //   path: "/role",
      //   name: "Role",
      //   meta: {
      //     title: "角色管理",
      //     icon: "icon-aside-home",
      //   },
      //   component: Role,
      // },
      // {
      //   path: "/user",
      //   name: "User",
      //   meta: {
      //     title: "用户列表",
      //     icon: "icon-aside-home",
      //   },
      //   component: User,
      // },
    ],
  },
  // {
  //   path: "/datas",
  //   name: "Datas",
  //   meta: {
  //     title: "数据统计",
  //     icon: "icon-aside-admin",
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/role",
  //       name: "Role",
  //       meta: {
  //         title: "角色管理",
  //         icon: "icon-aside-home",
  //       },
  //       component: Role,
  //     },
  //     {
  //       path: "/user",
  //       name: "User",
  //       meta: {
  //         title: "用户列表",
  //         icon: "icon-aside-home",
  //       },
  //       component: User,
  //     },
  //   ],
  // },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

export default router;
