import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import moment from "moment";
// import dayjs from "dayjs";
import axios from "axios";
// import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
// import "moment/locale/zh-cn";
//ant-design-vue
import Antd from "ant-design-vue";
// import { message } from 'ant-design-vue';
import "ant-design-vue/dist/antd.css";
//vue3-admin-master\node_modules\ant-design-vue\dist\antd-with-locales.js

// moment.locale("zh-cn");
// dayjs.locale("zh-cn");
let protocol = window.location.protocol;
let host = window.location.host;
host = host.indexOf("localhost") !== -1 ? "localhost:3080" : host;
axios.defaults.baseURL = protocol + "//" + host;

const app = createApp(App);

app.config.globalProperties.http = axios;
// let loading;
// function startLoading() {
//   loading = Vue.prototype.$loading({
//     lock: true,
//     text: "Loading...",
//     target: document.querySelector("body"), //设置加载动画区域
//   });
// }
let token = localStorage.getItem("token") || "";
axios.interceptors.request.use(
  (config) => {
    // startLoading();
    config.headers.common["token"] = localStorage.getItem("token") || "";
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
router.beforeEach((to, from, next) => {
  token = localStorage.getItem("token") || "";
  if (token) {
    next();
  } else {
    if (to.path == "/") {
      next();
    } else {
      next("/");
    }
  }
  // if (to.path == "/" || to.path == "") {
  //   if (token) {
  //     next("/enterprise");
  //   } else {
  //     next();
  //   }
  // } else {
  //   // requireAuth:可以在路由元信息指定哪些页面需要登录权限
  //   if (token) {
  //     next();
  //   } else {
  //     next("/");
  //   }
  // }
});

router.afterEach(function (to, from) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});
app.use(store).use(Antd).use(router).mount("#app");
