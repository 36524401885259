<template>
  <div class="aside">
    <div id="logo">
      <img :src="data.logo" alt="logo" srcset="" /><span
        v-if="!collapsed"
        style="padding-left: 10px; font-size: 20px"
        >UDI管理系统</span
      >
    </div>
    <a-menu
      mode="inline"
      theme="dark"
      v-model:openKeys="data.openKeys"
      v-model:selectedKeys="data.selectedKeys"
      @click="selectMenu"
      @openChange="openMenu"
    >
      <!-- 一级菜单 -->
      <template v-for="item in routers" :key="item.path">
        <template v-if="!item.hidden">
          <a-menu-item v-if="!item.children" :key="item.path">
            <router-link :to="item.path">
              <span class="anticon">
                <i
                  class="icon icon-size-21 mb--5 mr-4"
                  :class="item.meta && item.meta.icon"
                ></i> </span
              ><span :class="collapsed ? 'menu-color' : ''">{{
                item.meta && item.meta.title
              }}</span></router-link
            >
          </a-menu-item>
          <!-- 子级菜单 -->
          <Menu v-else :menu="item" :key="item.path" />
        </template>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import {
  PieChartOutlined,
  MailOutlined,
  DesktopOutlined,
  InboxOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "@vue/reactivity";
import Menu from "./Menu";
export default {
  name: "Aside",
  components: {
    PieChartOutlined,
    MailOutlined,
    DesktopOutlined,
    InboxOutlined,
    Menu,
  },
  emits: { selectMenu: null },
  props: {
    collapsed: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const { options } = useRouter();
    const routers = options.routes;
    // console.log(localStorage.getItem("selectedKeys"));
    const data = reactive({
      selectedKeys: localStorage.getItem("selectedKeys")
        ? [localStorage.getItem("selectedKeys")]
        : ["/index"],
      openKeys: localStorage.getItem("openKeys")
        ? localStorage.getItem("openKeys").split(",")
        : [],
      logo: require("@/assets/logo.svg"),
    });
    //选中菜单
    const selectMenu = (item) => {
      data.selectedKeys = [item.key];
      localStorage.setItem("selectedKeys", item.key);
      context.emit("selectMenu", item.keyPath);
    };
    //展开菜单
    const openMenu = (openKeys) => {
      data.openKeys = openKeys;
      localStorage.setItem("openKeys", openKeys);
    };
    return {
      data,
      routers,
      selectMenu,
      openMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
.aside {
  height: 100vh;
  overflow-y: auto;
}
#logo {
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #031932;
  color: #fff;
  white-space: nowrap;
  img {
    height: 30px;
    text-align: center;
  }
}
.menu-color {
  color: #a6adb4;
}
i.icon {
  opacity: 0.6;
}
.ant-menu-item-selected {
  i.icon {
    opacity: 1;
  }
}
/*设置滚动条样式*/
::-webkit-scrollbar {
  width: 1px; /*对垂直流动条有效*/
}
</style>
